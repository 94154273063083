.areas {
  width: 500px;
  margin-bottom: 24px;

  :global {
    tfoot td {
      background: #fafafa;
    }
  }
}
