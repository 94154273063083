.vaaContent {
  position: relative;
  min-height: calc(100% - 78px);
  padding-top: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 0.8rem;
  text-align: left;
  z-index: 2;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: repeat(auto-fill, 100px);
}

.currentLocation {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  vertical-align: middle;
  z-index: 401;
  color: #ff1d1d;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.currentLocationText {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 58%;
  left: 0;
  text-align: center;
  z-index: 401;
  color: #ff1d1d;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.overviewPlot {
  height: 80px;
  margin-bottom: 0.8rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  &:hover {
    -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 16px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 16px;
  }
}

@media only screen and (min-width: 800px) {
  .vaaContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .toolBar {
    text-align: right;
    margin-top: 0.8rem;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }

  .sortBy {
    width: 150px;
    text-align: left;
    margin-right: 0.8rem;
  }

  .dropDownArea {
    text-align: left;
  }
}

@media only screen and (min-width: 1200px) {
  .vaaContent {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1400px) {
  .vaaContent {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (max-width: 799px) {
  .toolBar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 8px 0.8rem;
    text-align: center;
    z-index: 999;

    :global {
      .ant-btn {
        position: absolute;
        right: 0.8rem;
        bottom: 22px;
        min-width: 50px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        padding: 3.3px 0;
        font-size: 20px;
      }
    }
  }

  .dropDownArea {
    text-align: left;
  }

  .sortBy {
    width: 200px;
    text-align: left;
    margin-right: 0.8rem;
  }
}
.overlay {
  height: 80px;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1000;
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
