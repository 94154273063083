.textCenter {
  text-align: center;
}

.legend {
  text-align: center;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

.marginBottom {
  margin-bottom: 10px;
}
