.spinWrapper {
  height: calc(100% - 80px);
  position: relative;
  & > div {
    height: 100%;
    position: relative;
  }
}

.planboardWrapper {
  height: calc(100% - 50px);
  position: relative;
  & > div {
    height: 100%;
  }
}
