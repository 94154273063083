.sidebar {
  z-index: 1;
  height: 100%;
}

.sidebarContent {
  position: relative;
  padding-left: 12px;
  height: 100%;
  overflow-y: auto;
}
