@import 'styles/global';

.cardButton {
  width: 100%;
  padding: 0;
  height: fit-content;
  margin-bottom: 8px;
  border-radius: 5px !important;
  border: 0 !important;
  &:hover {
    box-shadow: var(--primary) 0px 5px 15px;
  }

  &.selected {
    .card {
      :global {
        .ant-card {
          color: #fff;
          background-color: var(--primary) !important;
        }
      }
    }
  }
}

.cardButton .card {
  :global {
    .ant-card {
      margin-bottom: 0;
      border: 0;
    }
  }
}

.question {
  margin-bottom: 24px;
}
