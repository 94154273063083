@import 'styles/global';
button.button {
  svg ~ span {
    margin-left: 10px;
  }
}

button.primary {
  background-color: var(--primary);
  color: var(--text--primary);
  border: 1px solid var(--primary);
  &:hover {
    border-color: var(--primary--darker);
    color: var(--text--primary);
    background-color: var(--primary--darker);
  }
  &:active,
  &:focus {
    border-color: var(--primary--lighter);
    color: var(--text--primary);
    background-color: var(--primary--lighter);
  }
}

button.secondary {
  background-color: var(--secondary);
  color: var(--text--secondary);
  border: 1px solid var(--secondary);
  &:hover {
    border-color: var(--secondary--darker);
    color: var(--text--secondary);
    background-color: var(--secondary--darker);
  }
  &:active,
  &:focus {
    border-color: var(--secondary--lighter);
    color: var(--text--secondary);
    background-color: var(--secondary--lighter);
  }
}

button.tertiary {
  background-color: var(--tertiary);
  color: var(--text--tertiary);
  border: 1px solid var(--tertiary);
  &:hover {
    border-color: var(--tertiary--lighter);
    color: var(--text--tertiary);
    background-color: var(--tertiary--lighter);
  }
  &:active,
  &:focus {
    border-color: var(--tertiary--lightest);
    color: var(--text--tertiary);
    background-color: var(--tertiary--lightest);
  }
}

button.danger {
  background-color: var(--danger);
  color: var(--text--danger);
  border: 1px solid var(--danger);
  &:hover {
    border-color: var(--danger--lighter);
    color: var(--text-danger);
    background-color: var(--danger--lighter);
  }
  &:focus {
    border-color: var(--danger--lightest);
    color: var(--text--danger);
    background-color: var(--danger--lightest);
  }
}

button.white {
  background-color: #fff;
  color: gray;
  border: 1px solid gray;
  &:hover {
    border-color: var(--primary);
    color: var(--primary);
    background-color: #fff;
  }
  &:active,
  &:focus {
    border-color: var(--primary--lighter);
    color: var(--primary--lighter);
    background-color: #fff;
  }
}
