.activity {
  display: block;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 40px;
  z-index: 999;
}

.kaart {
  height: calc(100vh - 112px);
}
.kaartContainer {
  height: 100%;
}

.vaaContent {
  padding-bottom: 80px;
}
