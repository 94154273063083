.supportIcon {
  width: 40px;
  height: auto;
  list-style: none;
  line-height: 40px;
  margin-right: 20px;
  vertical-align: middle;
}

.title {
  display: inline-block;
}

.paragraph {
  margin-bottom: 18px;
}
