.header {
  background-color: #fff;
  padding: 0 20px;
  height: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  line-height: normal;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.userIcon {
  margin-right: 10px;
}

.modal {
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
}

.textRight {
  text-align: right;
}

.row {
  margin: 0;
  padding: 0;

  :global {
    .ant-menu-horizontal {
      line-height: 80px;
    }
  }
}

.title {
  font-weight: 700;
  font-family: 'Lora';
}

.titleMobile {
  font-weight: 600;
  font-family: 'Lora';
  font-size: 14px;
}

.impersonate {
  .value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-clamp: 1;
  }
  .label {
    font-weight: 500;
    text-align: right;
  }
}

.dggkIcon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  overflow: hidden;
  list-style: none;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
}
