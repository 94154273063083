.mainLayout {
  width: 100%;
  height: calc(100% - 80px);
  position: absolute;
}

.contentLayout {
  width: 100%;
  height: 100%;
  position: relative;
}
