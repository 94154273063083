.field {
  width: 90%;
}
.gebruikspercelen {
  display: inline-block;
  width: calc(100% - 65px);
  margin-right: 10px;
}

.footer {
  .delete {
    display: inline-block;
    width: 140px;
  }

  .mainButtons {
    text-align: right;
    width: calc(100% - 140px);
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: right;
  }
}

form > div > .ant-tabs-nav {
  display: flex;
  justify-content: center;
}
