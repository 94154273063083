.Modal {
  height: 100%;
  top: 0;
  padding-top: 24px;

  :global {
    .ant-modal-content {
      height: 100%;
    }

    .ant-modal-body {
      height: calc(100% - 106px);
    }
  }
}

.button {
  margin-top: 34px;
  width: 50px;
  border-radius: 5px;
}
